<template>
  <div>
    <div class="title">
      <i class="el-icon-back" @click="$router.go(-1)"></i>
    </div>
    <div class="content-view">
      <div class="content" v-for="item in feedbackList" :key="item.id">
        <div class="images">
          <img
            :src="img"
            alt=""
            v-for="(img, index) in item.images"
            :key="index"
          />
        </div>
        <div class="project-title">{{ item.name }}：{{ item.opinion }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      feedbackList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$api.mobile_project_opinion.list().then((res) => {
        this.feedbackList = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: left;
  font-size: 25px;
  padding: 30px 25px;
  color: #ffffff;
}
.content-view {
  height: 89vh;
  overflow: auto;
  .content {
    text-align: left;
    padding: 0 25px;
    margin-bottom: 45px;
    .images {
      display: flex;
      img {
        width: 25vw;
        height: 25vw;
        border-radius: 2px;
        &:nth-child(2n) {
          margin: 0 5%;
        }
      }
    }
    .project-title {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      margin-top: 18px;
    }
  }
}
</style>